import classNames from 'classnames'

export interface DefaultButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  type?: 'button' | 'submit' | 'reset'
  className?: string
  small?: boolean
  loading?: boolean
  disabled?: boolean
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  naked?: boolean
  isFocused?: boolean
  'data-testid'?: string
}

const DefaultButton = ({
  children,
  className,
  loading = false,
  disabled = false,
  type = 'button',
  small = false,
  leftIcon,
  rightIcon,
  naked = false,
  isFocused = false,
  ...rest
}: DefaultButtonProps) => (
  <button
    // eslint-disable-next-line react/button-has-type -- See ButtonProps
    type={type}
    // data-testid={rest?.['data-testid']}
    disabled={disabled || loading}
    className={classNames(
      {
        'cursor-not-allowed': disabled,
        'cursor-progress': loading,
        'bg-neutral-800 text-white hover:bg-neutral-700':
          !naked && !loading && !disabled,
        'bg-neutral-400 text-neutral-100': !naked && (loading || disabled),
        'border border-transparent text-sm font-normal shadow-sm focus:outline-none focus:ring-2 focus:ring-neutral-600 focus:ring-offset-2':
          !naked,
        '!ring-2 !ring-offset-2': isFocused,
      },
      small ? 'px-2 py-1' : 'px-4 py-2',
      'inline-flex items-center justify-center gap-3 rounded-md',
      className,
    )}
    {...rest}
  >
    {loading && (
      <svg
        className="pointer-events-none -ml-1 h-5 w-5 animate-spin text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    )}
    {!loading && leftIcon}
    {children}
    {rightIcon}
  </button>
)

export default DefaultButton
